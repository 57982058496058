import React from "react";
import { Rating } from "react-simple-star-rating";

/**
 *
 * @param params
 * @returns {JSX.Element}
 * @constructor
 */
export default function HeartRatingComponent(params) {
  return (
    <div>
      <Rating
        handleRating={params.handleRating}
        onClick={params.handleRating}
        ratingValue={params.ratingDefaultValue ?? 0}
        fillColor={params.fillColor ?? "#ff4d4e"}
        size={params.size ?? 45}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 24 24"
          fill="currentColor"
          style={{ marginRight: ".5rem" }}
        >
          <path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z" />
        </svg>
      </Rating>
    </div>
  );
}
