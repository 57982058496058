import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

// Custom components
import CustomNav from "../components/Nav/CustomNav";
import Footer from "../components/Footer/Footer";
import Stepper from "bs-stepper";
import HeartRatingComponent from "../components/Raiting/HeartRatingComponent";
import ReviewSuccessModal from "../components/ReviewModal/ReviewSuccessModal";

// Custom styles
import "bs-stepper/dist/css/bs-stepper.min.css";
import "../assets/scss/review.styles.scss";

// Media assets
import heartIconUrl from "../images/heart.svg";
import heartIconUrlEmpty from "../images/heart-empty.svg";
import pinIconUrl from "../images/icon-pin.svg";
import phoneIconUrl from "../images/icon-phone.svg";
import pharmacyIconUrl from "../images/icon-pharmacy.svg";
import clockIconUrl from "../images/clock.svg";
import fbIconUrl from "../images/icon-social-2.svg";
import waIconUrl from "../images/icon-social-1.svg";
import shareIconUrl from "../images/share--white.svg";
import userInfoIconUrl from "../images/user-review.svg";
import providerReviewIconUrl from "../images/provider-review.svg";
import funnyArrowIconUrl from "../images/funny-arrow.svg";

import pillIconUrl from "../images/pill--white.svg";
import MainMap from "../components/Maps/MainMap";
import ShareModal from "../components/ShareModal";
import axios from "axios";
import Swal from "sweetalert2";
import ReCaptchaComponent from "../components/Captcha/ReCaptchaComponent";
import { graphql } from "gatsby";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import RatingViewHeart from "../components/Raiting/RaitingViewHeart";
import FacilityIcon from "../components/Facility/FacilityTypeIcon";

let stepper;

let review = {
  providers_id: null,
  name_alias: "",
  age: "",
  gender: "",
  country: "",
  region: "",
  contraceptive: "",
  effectiveness: 0,
  easy_to_use: 0,
  side_effects: 0,
  contraceptive_comments: "",
  location: 0,
  attention: 0,
  response_time: 0,
  support: 0,
  hygiene: 0,
  privacy: 0,
  providers_comments: "",
  checkbox_policy: false,
};
let lngOffset = 0.014;

export default function Review(props) {
  const { t } = useTranslation();
  const { language } = useI18next();
  const query = new URLSearchParams(props.location?.search);
  const providerId = query.get("id");

  let [effectivenessRating, setEffectivenessRating] = useState(0); // initial rating value
  let [commodityRating, setCommodityRating] = useState(0); // initial rating value
  let [sideEffectsRating, setSideEffectsRating] = useState(0); // initial rating value
  let [locationRating, setLocationRating] = useState(0); // initial rating value
  let [attentionRating, setAttentionRating] = useState(0); // initial rating value
  let [supportRating, setSupportRating] = useState(0); // initial rating value
  let [responseTimeRating, setResponseTimeRating] = useState(0); // initial rating value
  let [hygieneRating, setHygieneRating] = useState(0); // initial rating value
  let [privacyRating, setPrivacyRating] = useState(0); // initial rating value
  let [childrenRating, setChildrenRating] = useState(0); // initial rating value
  const [termAndCondition, setTermAndCondition] = useState(false);
  let [breastfeedingRating, setBreastfeedingRating] = useState(0); // initial rating value
  const [shareModal, toggleShareModal] = useState(false);

  const [setContentLoadedProvider] = useState(false);
  const [myDataProvider, setMyDataProvider] = useState({});
  let provider = [];
  let providersMarkers = [];
  let miniMap = null;

  useEffect(() => {
    stepper = new Stepper(document.querySelector("#stepper1"), {
      linear: false,
      animation: true,
    });

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      lngOffset = 0;
    }
  }, []);

  // Catch Rating value
  const handleEffectivenessRating = (rate) => {
    setEffectivenessRating(rate);
    effectivenessRating = rate;
    review.effectiveness = rate;
  };

  const handleCommodityRating = (rate) => {
    setCommodityRating(rate);
    commodityRating = rate;
    review.easy_to_use = rate;
  };

  const handleSideEffectsRating = (rate) => {
    setSideEffectsRating(rate);
    sideEffectsRating = rate;
    review.side_effects = rate;
  };

  const handleLocationRating = (rate) => {
    setLocationRating(rate);
    locationRating = rate;
    review.location = rate;
  };

  const handleAttentionRating = (rate) => {
    setAttentionRating(rate);
    attentionRating = rate;
    review.attention = rate;
  };

  const handleResponseTimeRating = (rate) => {
    setResponseTimeRating(rate);
    responseTimeRating = rate;
    review.response_time = rate;
  };

  const handleSupportRating = (rate) => {
    setSupportRating(rate);
    supportRating = rate;
    review.support = rate;
  };

  const handleHygieneRating = (rate) => {
    setHygieneRating(rate);
    hygieneRating = rate;
    review.hygiene = rate;
  };

  const handlePrivacyRating = (rate) => {
    setPrivacyRating(rate);
    privacyRating = rate;
    review.privacy = rate;
  };

  const handleChildrenRating = (rate) => {
    setChildrenRating(rate);
    childrenRating = rate;
    review.Children = rate;
  };

  const handleBreastfeedingRating = (rate) => {
    setBreastfeedingRating(rate);
    breastfeedingRating = rate;
    review.Breastfeeding = rate;
  };

  useEffect(() => {
    fetch(`${process.env.GATSBY_REST_API_URL}/provider/${providerId}`)
      .then((responseProvider) => responseProvider.json())
      .then((resultDataProvider) => {
        setMyDataProvider(resultDataProvider);
      });
  }, []);

  useEffect(() => {
    myDataProvider.reviews && setContentLoadedProvider(true);
  }, [myDataProvider]);
  if (!isEmpty(myDataProvider)) {
    provider = myDataProvider;
    console.log('provider-->'+JSON.stringify(provider));
    providersMarkers = [
      {
        id: provider.id,
        latitude: parseFloat(provider.latitude),
        longitude: parseFloat(provider.longitude),
        title: provider.name,
        clickable: false
      },
    ];
    miniMap = (
      <MainMap
        default_lat={providersMarkers[0]?.latitude}
        default_lng={providersMarkers[0]?.longitude + lngOffset}
        zoom={15}
        markers={providersMarkers}
        height={"750px"}
        defaultStyle={false}
        geometryColor="#aff5cd"
        lines="#4aec91"
      />
    );
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const [submitReview, setSubmitReview] = useState(false);
  const [reviewerName, setReviewerName] = useState("");
  function closeModal(e) {
    setSubmitReview(e);
    setReviewerName("");
  }

  const [token, setToken] = useState("");
  const onGetTokenRecaptcha = (e) => {
    setToken(e);
  };
  const onExpiredTokenRecaptcha = (e) => {
    setToken("");
  };

  const onSubmit = (e) => {
    review.providers_id = providerId;
    review.experience =
      document.getElementById('experience_textarea').value + " Contraceptive Comments: \n " + document.getElementById('providers_comment_textarea').value;
    delete review.providers_comments;
    delete review.contraceptive_comments;
    delete review.checkbox_policy;
    let privacyCheck = document.getElementById("privacyCheck");

    if (
      review.name_alias == null ||
      review.name_alias == "" ||
      review.age == t("Choose...") ||
      review.region == t("Choose...") ||
      review.country == t("Choose...") ||
      review.gender == t("Choose...") ||
      privacyCheck.checked == false ||
      termAndCondition === false
    ) {
      e.preventDefault();
      Swal.fire({
        icon: "error",
        title: "Error",
        text: t("There are some fields missing in the form"),
      });

      return;
    }

    if (!token) {
      e.preventDefault();
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please validate recaptcha",
      });

      return;
    }

    let optionAxios = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(
        `${process.env.GATSBY_REST_API_URL}/reviews/provider/${providerId}`,
        { review: review, token, termAndCondition },
        optionAxios
      )
      .then(function (response) {
        // Swal.fire({
        //   icon: "success",
        //   title: `${t("Thanks")} ${review.name_alias}`,
        //   text: t(
        //     `We have received your review and we will redirect you to the provider's profile so that you can continue surfing`
        //   ),
        // });
        setReviewerName(review.name_alias);
        setSubmitReview(true);
        setTimeout(function () {
          window.location.href =
            process.env.GATSBY_SITE_URL + "/provider/" + review.providers_id;
        }, 5000);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${t("There was an error saving the review")} ${error.message}`,
        });
      });

    e.preventDefault();
  };

  function setName(slices) {
    review.name_alias = slices.name;
  }

  function setAge(param) {
    review.age = param.age;
  }

  function setGenre(param) {
    review.gender = param.genre;
  }

  function setCountry(param) {
    review.country = param.country;
  }

  function setRegion(param) {
    review.region = param.region;
  }

  function setContraceptive(param) {
    review.contraceptive = param.contraceptive;
  }

  function setContraceptiveComments(param) {
    review.contraceptive_comments = param.contraceptive_comments;
  }

  function setProvidersComments(param) {
    review.providers_comments = param.providers_comments;
  }

  function setCheckboxPolicy(param) {
    review.checkbox_policy = param.checkbox_policy;
  }

  useEffect(() => window.scrollTo(0, 800), []);

  const nextForm = (e) => {
    e.preventDefault();
    stepper.next();
  };

  const showShareModal = (e) => {
    e.preventDefault();
    toggleShareModal(window.location.href);
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: language }}>
        <meta charSet="utf-8" />
        {/* <!-- HTML Meta Tags --> */}
        <title>{t("Find My Method: Contraceptive Provider Reviews")}</title>
        <meta
          name="description"
          content={t(
            "Write reviews for the providers, services and products. Help other users access quality services based on your experience."
          )}
        />
        <meta
          name="keywords"
          content="Contraceptive Services - 150 global search volume, contraceptive method"
        />
        {/* <!-- END of HTML Meta Tags --> */}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://findmymethod.org/wp-content/uploads/2019/05//apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://findmymethod.org/wp-content/uploads/2019/05//favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://findmymethod.org/wp-content/uploads/2019/05//favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="https://findmymethod.org/wp-content/uploads/2019/05//site.webmanifest"
        />
        <link
          rel="mask-icon"
          href="https://findmymethod.org/wp-content/uploads/2019/05//safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#5bbad5" />
        <meta name="theme-color" content="#ffffff" />
        <title>{t("New Review")}</title>
        <link rel="canonical" href={process.env.GATSBY_SITE_URL}/>
      </Helmet>
      <CustomNav theme="dark" />
      <main>
        <section className="provider-hero">
          <Row className="h-100 g-0">
            {miniMap}
            <Col xs={12} lg={6} />
            <Col xs={12} lg={6}>
              <div className="provider-hero__container">
                <div className="provider-hero__reviews__rating">
                  <RatingViewHeart val={provider?.average ?? 5} />
                </div>
                <h3>{provider?.name}</h3>
                <div className="provider-hero__type">
                  <div>
                    {FacilityIcon(provider?.facility_human_name)}
                    {/* <img src={pharmacyIconUrl} alt="Pharmacy" /> */}
                    <p>{provider?.facility_human_name}</p>
                  </div>
                  <div>
                    <img src={clockIconUrl} alt="Contraceptive Services" />
                    <p>{provider?.facility_human_name}</p>
                  </div>
                </div>
                <div className="provider-hero__info">
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${provider?.address}`}
                    target="_blank"
                  >
                    <img src={pinIconUrl} alt="Contraceptive Services" />
                    {provider?.address}
                  </a>
                  <a href={`tel:${provider?.phone_contact}`}>
                    <img src={phoneIconUrl} alt="Contraceptive Services" />
                    {provider?.phone_contact}
                  </a>
                </div>
                <div className="provider-hero__social">
                  <a href={provider?.facebook_link}>
                    <img src={fbIconUrl} alt="Contraceptive Services" />
                  </a>
                  <a href={`https://wa.me/${provider?.whatsapp_contact}`}>
                    <img src={waIconUrl} alt="Contraceptive Services" />
                  </a>
                </div>
                <div className="provider-hero__cta">
                  <a href="" className="btn--blue">
                    {t("Write a review")}
                  </a>
                  <a href="#" className="btn--blue" onClick={showShareModal}>
                    <img src={shareIconUrl} alt="FMM" />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </section>
        <section className="review" style={{ marginTop: "5%" }}>
          <Container>
            <h2>{t("Fill out the following questionnaire")}</h2>
            <p>
              {t(
                "Thanks for sharing your experience, this review will help other to access quality service."
              )}
            </p>
            <div className="review__container">
              <div id="stepper1" className="bs-stepper">
                <div className="bs-stepper-header">
                  <div className="step" data-target="#test-l-1">
                    <button className="step-trigger">
                      <span className="bs-stepper-circle">
                        <img
                          src={userInfoIconUrl}
                          alt="Contraceptive Services"
                        />{" "}
                        {t("User information")}
                      </span>
                    </button>
                  </div>
                  <div className="line"></div>
                  <div className="step" data-target="#test-l-2">
                    <button className="step-trigger">
                      <span className="bs-stepper-circle">
                        <img src={pillIconUrl} alt="Contraceptive Services" />{" "}
                        {t("Contraceptive")}
                      </span>
                    </button>
                  </div>
                  <div className="line"></div>
                  <div className="step" data-target="#test-l-3">
                    <button className="step-trigger">
                      <span className="bs-stepper-circle">
                        <img
                          src={providerReviewIconUrl}
                          alt="Contraceptive Services"
                        />{" "}
                        {t("Provider")}
                      </span>
                    </button>
                  </div>
                </div>
                <div className="bs-stepper-content">
                  <form onSubmit={onSubmit}>
                    <div id="test-l-1" className="content">
                      <p>
                        {t("Remember your answers are completely anonymous.")}
                      </p>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          xs={12}
                          lg={4}
                          controlId="formGridEmail"
                          className="mb-3 mt-lg-0"
                        >
                          <Form.Label>{t("Name or alias")}*</Form.Label>
                          <Form.Control
                            type="text"
                            onChange={(e) => setName({ name: e.target.value })}
                          />
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          xs={12}
                          lg={4}
                          controlId="formGridState"
                        >
                          <Form.Label>{t("Age")}*</Form.Label>
                          <Form.Select
                            defaultValue={t("Choose...")}
                            onChange={(e) => setAge({ age: e.target.value })}
                          >
                            <option>{t("Choose...")}</option>
                            <option>Under 18</option>
                            <option>18 - 25</option>
                            <option>26 - 35</option>
                            <option>36 - 45</option>
                            <option>Over 45</option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          xs={12}
                          lg={4}
                          controlId="formGridState"
                        >
                          <Form.Label>{t("Gender")}*</Form.Label>
                          <Form.Select
                            defaultValue={t("Choose...")}
                            onChange={(e) =>
                              setGenre({ genre: e.target.value })
                            }
                          >
                            <option>{t("Choose...")}</option>
                            <option>{t("Female")}</option>
                            <option>{t("Male")}</option>
                            <option>{t("Non-binary")}</option>
                            <option>{t("Prefer not to answer")}</option>
                            <option>{t("Prefer to self-describe")}</option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          xs={12}
                          lg={4}
                          controlId="formGridState"
                        >
                          <Form.Label>{t("Country")}*</Form.Label>
                          <Form.Select
                            defaultValue={t("Choose...")}
                            onChange={(e) =>
                              setCountry({ country: e.target.value })
                            }
                          >
                            <option>{t("Choose...")}</option>
                            <option>{t("Mexico")}</option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          xs={12}
                          lg={4}
                          controlId="formGridState"
                        >
                          <Form.Label>{t("Region")}*</Form.Label>
                          <Form.Select
                            defaultValue="Choose..."
                            onChange={(e) =>
                              setRegion({ region: e.target.value })
                            }
                          >
                            <option>{t("Choose...")}</option>
                            <option>{t("Mexico City")}</option>
                          </Form.Select>
                        </Form.Group>
                      </Row>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => nextForm(e)}
                      >
                        {t("Next")}
                        <img
                          src={funnyArrowIconUrl}
                          alt="Contraceptive Services"
                        />
                      </button>
                    </div>
                    <div id="test-l-2" className="content">
                      <p>{t("How’s your experience with contraceptives?")}</p>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          xs={12}
                          lg={4}
                          controlId="formGridState"
                        >
                          <Form.Label>{t("Contraceptive")}*</Form.Label>
                          <Form.Select
                            defaultValue={t("Choose...")}
                            onChange={(e) =>
                              setContraceptive({
                                contraceptive: e.target.value,
                              })
                            }
                          >
                            <option id={0} value={0}>
                              {t("Choose...")}
                            </option>
                            <option id={1} value={1}>
                              {t("External Condoms")}
                            </option>
                            <option id={2} value={2}>
                              {t("Internal Condoms")}
                            </option>
                            <option id={3} value={3}>
                              {t("Daily contraceptive pill")}
                            </option>
                            <option id={4} value={4}>
                              {t("Contraceptive injection")}
                            </option>
                            <option id={5} value={5}>
                              {t("Patch")}
                            </option>
                            <option id={6} value={6}>
                              {t("Ring")}
                            </option>
                            <option id={7} value={7}>
                              {t("Emergency contraceptive pill")}
                            </option>
                            <option id={8} value={8}>
                              {t("Cervical cap")}
                            </option>
                            <option id={9} value={9}>
                              {t("Diaphragm")}
                            </option>
                            <option id={10} value={10}>
                              {t("Spermicide")}
                            </option>
                            <option id={11} value={11}>
                              {t("Sponge")}
                            </option>
                          </Form.Select>
                        </Form.Group>
                        <Row>
                          <Col
                            xs={12}
                            md={4}
                            className="questionnaire__contraceptive__rating"
                          >
                            <p>{t("Effectiveness")}*</p>
                            <HeartRatingComponent
                              handleRating={handleEffectivenessRating}
                              ratingDefaultValue={effectivenessRating}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            className="questionnaire__contraceptive__rating"
                          >
                            <p>{t("Comfort")}*</p>
                            <HeartRatingComponent
                              handleRating={handleCommodityRating}
                              ratingDefaultValue={commodityRating}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            className="questionnaire__contraceptive__rating"
                          >
                            <p>{t("Side effects")}*</p>
                            <HeartRatingComponent
                              handleRating={handleSideEffectsRating}
                              ratingDefaultValue={sideEffectsRating}
                            />
                          </Col>
                        </Row>
                        {/*<Row>
                          <Col
                              xs={12}
                              md={4}
                              className="questionnaire__contraceptive__rating"
                          >
                            <p>{t('Children')}*</p>
                            <HeartRatingComponent
                                handleRating={handleChildrenRating}
                                ratingDefaultValue={childrenRating}
                            />
                          </Col>
                          <Col
                              xs={12}
                              md={4}
                              className="questionnaire__contraceptive__rating"
                          >
                            <p>{t('Breast Feeding')}*</p>
                            <HeartRatingComponent
                                handleRating={handleBreastfeedingRating}
                                ratingDefaultValue={breastfeedingRating}
                            />
                          </Col>
                        </Row>*/}
                        <Form.Group
                          as={Col}
                          xs={12}
                          className="mt-3"
                          controlId="experience_textarea"
                        >
                          <Form.Label>
                            {t("How’s your experience with contraceptives?")}
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={7}
                            onChange={(e) =>
                              setContraceptiveComments({
                                contraceptive_comments: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        {/* <Form.Group
                          as={Col}
                          xs={12}
                          lg={4}
                          controlId="formGridEmail"
                          className="mb-3 mt-lg-0"
                        >
                          <Form.Label>Name or Alias*</Form.Label>
                          <Form.Control type="text" />
                        </Form.Group> */}
                      </Row>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => nextForm(e)}
                      >
                        {t("Next")}
                        <img
                          src={funnyArrowIconUrl}
                          alt="Contraceptive Services"
                        />
                      </button>
                    </div>
                    <div id="test-l-3" className="content">
                      <p>{t("Score this provider")}</p>
                      <Row>
                        <Col
                          xs={12}
                          md={6}
                          className="questionnaire__contraceptive__rating"
                        >
                          <p>{t("Location")}*</p>
                          <HeartRatingComponent
                            handleRating={handleLocationRating}
                            ratingDefaultValue={locationRating}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          className="questionnaire__contraceptive__rating"
                        >
                          <p>{t("Attention")}*</p>
                          <HeartRatingComponent
                            handleRating={handleAttentionRating}
                            ratingDefaultValue={attentionRating}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          className="questionnaire__contraceptive__rating"
                        >
                          <p>{t("Response time")}*</p>
                          <HeartRatingComponent
                            handleRating={handleResponseTimeRating}
                            ratingDefaultValue={responseTimeRating}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          className="questionnaire__contraceptive__rating"
                        >
                          <p>{t("Support")}*</p>
                          <HeartRatingComponent
                            handleRating={handleSupportRating}
                            ratingDefaultValue={supportRating}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          className="questionnaire__contraceptive__rating"
                        >
                          <p>{t("Hygiene")}*</p>
                          <HeartRatingComponent
                            handleRating={handleHygieneRating}
                            ratingDefaultValue={hygieneRating}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          className="questionnaire__contraceptive__rating"
                        >
                          <p>{t("Privacy")}*</p>
                          <HeartRatingComponent
                            handleRating={handlePrivacyRating}
                            ratingDefaultValue={privacyRating}
                          />
                        </Col>
                      </Row>
                      <Form.Group
                        as={Col}
                        xs={12}
                        className="mt-4"
                        controlId="providers_comment_textarea"
                      >
                        <Form.Label>
                          {t("How can this provider improve their service?")}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={7}
                          onChange={(e) =>
                            setProvidersComments({
                              providers_comments: e.target.value,
                            })
                          }
                        />
                        <section className="provider__submission">
                          <Container>
                            <Row>
                              <Col xs={12} lg={6} className="text-center">
                                <Form.Group id="formGridCheckbox">
                                  <Form.Check
                                    id={"privacyCheck"}
                                    onChange={(e) =>
                                      setCheckboxPolicy({
                                        checkbox_policy: e.target.value,
                                      })
                                    }
                                  >
                                    <Form.Check.Label
                                    // style={{ color: errors.termAndCondition ? "red" : "" }}
                                    >
                                      <Form.Check.Input
                                        style={{ backgroundColor: "#0d6efd" }}
                                        type={"checkbox"}
                                        onChange={(e) => setTermAndCondition(e.target.value)}
                                      />{" "}
                                      {t(
                                        "By clicking this box you accept the use of the information to be listed in our directory. For more information about the use of your data, please read our"
                                      )}{" "}
                                      <a
                                        href={t("url_terms_conditions")}
                                        target={"_blank"}
                                      >
                                        {t("Terms and Conditions")}
                                      </a>{" "}
                                      {t("and the")}{" "}
                                      <a
                                        href={t("url_privacy_policy")}
                                        target={"_blank"}
                                      >
                                        {t("Privacy Policy")}
                                      </a>
                                    </Form.Check.Label>
                                  </Form.Check>
                                </Form.Group>
                              </Col>
                              <Col xs={12} lg={6} className="text-center">
                                <ReCaptchaComponent
                                  id={"captcha-review"}
                                  onGetTokenRecaptcha={onGetTokenRecaptcha}
                                  onExpiredTokenRecaptcha={
                                    onExpiredTokenRecaptcha
                                  }
                                />
                              </Col>
                            </Row>
                          </Container>
                          {/* <div>
                        </div> */}
                        </section>
                      </Form.Group>
                      <button type="submit" className="btn btn-primary mt-5">
                        {t("Submit review")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </main>
      <ShareModal isShow={shareModal} onClose={() => toggleShareModal(false)} url={shareModal} />
      {submitReview ? (
        <ReviewSuccessModal
          name={reviewerName}
          show={submitReview}
          close={closeModal}
        />
      ) : (
        ""
      )}
      <Footer />
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
