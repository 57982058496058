import React, { useState } from "react";

// Custom styles
import "./reviewSuccessModal.styles.scss";

// Media assets
import logo from "../../images/fmm-logo.svg";

// Bootstrap components
import Modal from "react-bootstrap/Modal";

import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

export default function ReviewSuccessModal({ name, show, close }) {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(show);

  const handleModalClose = () => {
    close(false);
    setShowModal(false);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      className="modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal__container">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="modal__container__body">
          <div className="logo">
            <img src={logo} alt="FMM" />
          </div>
          <h2 className="title">
            {t("Thanks")} {name}!
          </h2>
          <div className="text">
            {t(
              "We have received your review and we will redirect you to the provider's profile so that you can continue surfing."
            )}
          </div>
          <button className="btn btn-redirect mt-5">{t("Redirect now")}</button>
        </Modal.Body>
      </div>
    </Modal>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
