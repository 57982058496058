import React from "react";
import Modal from "react-bootstrap/Modal";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
  EmailIcon,
  FacebookIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
} from "react-share";
import "./shareModal.styles.scss";

const ShareModal = ({ isShow, onClose, url }) => {
  return (
    <Modal
      show={isShow}
      onHide={onClose}
      className="modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal__container shareModal">
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body className="modal__container__body">
          <div className="row">
            <div className="col">
              <EmailShareButton
                url={url}
                body="body"
                className="Demo__some-network__share-button"
              >
                <EmailIcon size={64} round />
              </EmailShareButton>
            </div>

            <div className="col">
              <FacebookShareButton
                url={url}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={64} round />
              </FacebookShareButton>
            </div>
            <div className="col">
              <WhatsappShareButton
                url={url}
                separator=":: "
                className="Demo__some-network__share-button"
              >
                <WhatsappIcon size={64} round />
              </WhatsappShareButton>
            </div>

            {/* TODO: Update appId */}
            <div className="col">
              <FacebookMessengerShareButton
                url={url}
                appId="521270401588372"
                className="Demo__some-network__share-button"
              >
                <FacebookMessengerIcon size={64} round />
              </FacebookMessengerShareButton>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ShareModal;
